<app-uni-page [parent]="this" pageTitle="Job Order - Invoices" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <div style="width: 100%; height: 95px;">
        <app-ag-grid [gridOptions]="johGridOptions" [rowData]="joHeaders"></app-ag-grid>
      </div>

      <div style="width: 100%; height: calc(100% - 95px);">
        <app-ag-grid [gridOptions]="invhGridOptions" [rowData]="invoiceHeaders"></app-ag-grid>
      </div>
    </as-split-area>

    <as-split-area [size]="50">

      <mat-tab-group *ngIf="invoiceHeader">
        <mat-tab label="General">
          <ng-template matTabContent>
            <div class="mt-12 ml-12">
              <div uni-flex-row>
                <mat-form-field [matTooltip]="invoiceHeader.id">
                  <input matInput placeholder="Invoice #" [ngModel]="invoiceHeader.id" readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="''+invoiceHeader.invoiceDate">
                  <input matInput placeholder="Invoice Date" [ngModel]="invoiceHeader.invoiceDate  | date: 'MM/dd/yyyy'"
                    readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="''+invoiceHeader.dueDate">
                  <input matInput placeholder="Due Date" [ngModel]="invoiceHeader.dueDate  | date: 'MM/dd/yyyy'"
                    readonly="readonly" />
                </mat-form-field>
              </div>
              <div uni-flex-row>
                <mat-form-field [matTooltip]="dbLookup('InvoiceStatus', invoiceHeader.invoiceStatusId)">
                  <input matInput placeholder="Status"
                    [ngModel]="dbLookup('InvoiceStatus', invoiceHeader.invoiceStatusId)" readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="invoiceHeader.id">
                  <input matInput placeholder="Job Order" [ngModel]="invoiceHeader.joHeaderId" readonly="readonly" />
                </mat-form-field>
              </div>
              <div uni-flex-row>
                <mat-form-field>
                  <input matInput placeholder="Extended Amt" [ngModel]="invoiceHeader.calcExtendedAmt() | currency"
                    readonly="readonly" />
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="Extended Reissue Amt"
                    [ngModel]="invoiceHeader.calcExtendedReissueAmt() | currency" readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="''+joHeaders[0].taxRate">
                  <input matInput placeholder="Tax Rate" [ngModel]="joHeaders[0].taxRate" readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="''+invoiceHeader.taxAmt">
                  <input matInput placeholder="Tax Amt" [ngModel]="(invoiceHeader.taxAmt || 0)| currency"
                    readonly="readonly" />
                </mat-form-field>
              </div>
              <div uni-flex-row>
                <ng-container *ngIf="invoiceHeader.wasMigrated; else notMigrated">
                  <mat-form-field [matTooltip]="''+invoiceHeader.freightAmt">
                    <input matInput placeholder="Freight" [ngModel]="invoiceHeader.freightAmt | currency"
                      readonly="readonly" />
                  </mat-form-field>
                </ng-container>
                <ng-template #notMigrated>
                  <mat-form-field *ngIf="invoiceHeader.isPrimaryInvoice && invoiceHeader?.shipment"
                    [matTooltip]="''+invoiceHeader.calcShipFreightAmt()">
                    <input matInput placeholder="Freight" [ngModel]="invoiceHeader.calcShipFreightAmt() | currency"
                      readonly="readonly" />
                  </mat-form-field>
                </ng-template>
                <mat-form-field [matTooltip]="''+invoiceHeader.orderHandlingAmt">
                  <input matInput placeholder="Handling" [ngModel]="(invoiceHeader.orderHandlingAmt || 0) | currency"
                    readonly="readonly" />
                </mat-form-field>
                <mat-form-field [matTooltip]="''+invoiceHeader.calcTotalAmt()">
                  <input matInput placeholder="Total" [ngModel]="invoiceHeader.calcTotalAmt() | currency"
                    readonly="readonly" />
                </mat-form-field>
                <mat-form-field *ngIf="shouldShowMigratedTotal(invoiceHeader)">
                  <input matInput placeholder="Migrated Total" [ngModel]="invoiceHeader.totalAmt | currency"
                    readonly="readonly" />
                </mat-form-field>
              </div>
              <div uni-flex-row>
                <div>
                  {{ invoiceHeader.shipFreightInfo }}
                </div>
              </div>
              <div uni-flex-row *ngIf="canDisplayAltVoucherMessage()">
                <div>
                  <b>Note: There are {{ this.vouchersWithSameAddress?.length }} other voucher(s) for this same
                    account/address that are not on this shipment. </b>
                </div>
              </div>
              <div uni-flex-row *ngIf="invoiceHeader?.invoiceStatusId === 'POSTED'">
                <div>
                  <a style="color:blue" href="{{ getQuickbooksLink(invoiceHeader) }}" target="_blank">See invoice
                    {{ invoiceHeader.id }} in Quickbooks</a>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Bill To">
          <ng-template matTabContent>
            <div class="mt-12 ml-12">
              <app-address-component [entity]="invoiceHeader.joHeader" [prefix]="'billing'" [isReadonly]='true'>
              </app-address-component>
              <button [disabled]="!canEditAddress('billing')" mat-raised-button
                (click)="editAddress('billing','Billing')">Edit
                Address</button>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Ship To">
          <div class="mt-12 ml-12">
            <app-address-component [entity]="invoiceHeader.joHeader" [prefix]="'shipping'"></app-address-component>
            <button [disabled]="!canEditAddress('shipping')" mat-raised-button
              (click)="editAddress('shipping','Shipping')">Edit
              Address</button>
          </div>
        </mat-tab>
        <mat-tab label="Invoice Details" id="group">
          <ng-template matTabContent>
            <app-ag-grid [gridOptions]="invdGridOptions" [rowData]="invoiceDetails"></app-ag-grid>
          </ng-template>
        </mat-tab>
        <!-- Removed per discussion with Jeff - 4/2x/2020 -->
        <!-- <mat-tab label="Job Order Details" id="group">
            <ng-template matTabContent>
              <app-ag-grid [gridOptions]="jodGridOptions" [rowData]="joDetails" ></app-ag-grid>  
            </ng-template>
          </mat-tab> -->
        <mat-tab label="Item Details" id="group">
          <ng-template matTabContent>
            <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails"></app-ag-grid>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="(!invoiceHeader?.wasMigrated) && invoiceHeader?.isPrimaryInvoice" label="Freight">
          <ng-template matTabContent>
            <div uni-flex-row>
              <mat-form-field>
                <input matInput placeholder="Carrier" [ngModel]="getCarrierDisplayName(invoiceHeader)"
                  readonly="readonly" />
              </mat-form-field>
              <mat-form-field style="width: 250px">
                <input matInput placeholder="Service" [ngModel]="getServiceDisplayName(invoiceHeader)"
                  readonly="readonly" />
              </mat-form-field>
              <mat-form-field [matTooltip]="invoiceHeader.shipment.shipmentApiIdentifier">
                <input matInput placeholder="ShipEngine Shipment Id"
                  [ngModel]="invoiceHeader.shipment.shipmentApiIdentifier" readonly="readonly" />
              </mat-form-field>
              <!-- <mat-form-field >
                  <input matInput placeholder="Unit of Measure"
                    [value]= "uomMap[invoiceHeader.shipment.weightUoMId]" readonly="readonly" />
                </mat-form-field> -->
            </div>
            <div uni-flex-row>
              <mat-form-field *ngIf="invoiceHeader.shipment.primaryInvoiceHeaderId == invoiceHeader.id">
                <input matInput placeholder="Freight" [ngModel]="invoiceHeader.shipment.freightAmt | currency"
                  readonly="readonly" />
              </mat-form-field>
              <mat-form-field *ngIf="invoiceHeader.shipment.primaryInvoiceHeaderId != invoiceHeader.id">
                <input matInput placeholder="Freight"
                  [ngModel]="'On invoice: ' + invoiceHeader.shipment.primaryInvoiceHeaderId" readonly="readonly" />
              </mat-form-field>

              <mat-form-field [matTooltip]="''+invoiceHeader.orderHandlingAmt">
                <input matInput placeholder="Handling" [ngModel]="(invoiceHeader.orderHandlingAmt || 0) | currency"
                  readonly="readonly" />
              </mat-form-field>
            </div>
            <div uni-flex-row>
              <div>
                {{ invoiceHeader.shipFreightInfo }}
              </div>
            </div>
            <app-ag-grid [gridOptions]="sboxGridOptions" [rowData]="shippingBoxes"></app-ag-grid>
            <ng-template #shipLabelCell let-box>
              <button *ngIf="!!box.shippingLabel" (click)="printBoxLabel(box)">Print Label</button>
            </ng-template>

          </ng-template>
        </mat-tab>
      </mat-tab-group>

    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <!-- <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
  <ng-template #notBusy> -->
    <div class="left-buttons">
      <button mat-raised-button [disabled]="!canUndoInvoice(invoiceHeader)" (click)="undoInvoice(invoiceHeader)">Undo
        Invoice
      </button>
      <!-- <button mat-raised-button [disabled]="!canCalcFreight(invoiceHeader)"
        (click)="calcFreight(invoiceHeader)">Calculate
        Freight
      </button> -->
      <!-- <button mat-raised-button *ngIf="canPrintLabel(invoiceHeader)" (click)="printLabels(invoiceHeader)">Print
        Shipping Label(s)
      </button>
      <button mat-raised-button *ngIf="canVoidLabel(invoiceHeader)" (click)="voidLabel(invoiceHeader)">Void Shipping
        Label
      </button>
      <button mat-raised-button *ngIf="canRemoveVoidError(invoiceHeader)" (click)="removeVoidError(invoiceHeader)">Force
        Void Error removal
      </button> -->
      <button *ngIf="invoiceHeader != null" mat-raised-button (click)="goPrintableInvoice()">
        <span>Print Preview</span>
      </button>
    </div>
    <div>
      <button mat-raised-button (click)="goShipping()">Shipping
      </button>
      <button mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  <!-- </ng-template> -->
</ng-template>