import { GridOptions, InitialGroupOrderComparatorParams } from '@ag-grid-community/core';
import { Component, ElementRef, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Addon, ItemDetail, ProductTypeAlt } from 'app/model/entities/entity-model';
import { JoDetail } from 'app/model/entities/jo-detail';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AddonUtilsService } from 'app/shared/addon-utils.service';
import { AgFns } from 'app/shared/ag-fns';
import { BarcodeService } from 'app/shared/barcode.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-jo-pull-apply',
    templateUrl: './jo-pull-apply.component.html',
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class JoPullApplyComponent extends DomainBaseComponent {
  @ViewChild('confirmButton') confirmButton: ElementRef;
  @ViewChild('selectItemDetailCell') selectItemDetailCell: TemplateRef<any>;

  joPullBatchId: number;
  joDetailId: number;
  accountAddons: Addon[];

  shouldPrintBarcodes: boolean = true;

  joDetails: JoDetail[];
  jodGridOptions: GridOptions;

  matchingItemDetails: ItemDetail[];
  itdMatchGridOptions: GridOptions;
  aggFuncs: any[];

  itemDetailId: string;
  itemDetails: ItemDetail[];
  itdGridOptions: GridOptions;

  isStyleSizeMismatch: boolean;

  isValidAlternative: boolean;
  alternativeAlreadyExists: boolean;
  
  errorMessage = '';

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, 
    protected addonUtilsService: AddonUtilsService, protected barcodeService: BarcodeService) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.joPullBatchId = +this.route.snapshot.params['joPullBatchId'];
    this.joDetailId = +this.route.snapshot.params['joDetailId'];
    this.shouldPrintBarcodes = this.route.snapshot.params['shouldPrintBarcodes'] == 'true'

    this.jodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJodGridReady,
      pagination: true,
    }, { detailProperty: 'joDetailAddons' });

    this.itdMatchGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdMatchGridReady,
      onRowGroupOpened: AgFns.autoSizeAllColumns,
      suppressAggFuncInHeader: true,
      onModelUpdated: AgFns.autoSizeAllColumns,
    });

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
      onModelUpdated: AgFns.autoSizeAllColumns,
    }, { detailProperty: 'itemDetailAddons' });

    const joDetail = await this.dbQueryService.getJoDetailById(this.joDetailId);
    if (joDetail) {
      this.joDetails = [joDetail];
    } else {
      // TODO: how do we handle this
    }

    this.accountAddons = await this.dbQueryService.getAddons(joDetail.joHeader.accountId);

    this.isPageReady = true;
    // page can be ready before this returns.
    this.matchingItemDetails = await this.getMatchingItemDetails(joDetail);
  }

  onJodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'JO Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name',  },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'product.featureChoicesExtract',  },
      { headerName: 'Order Qty', field: 'orderQty',  },
      { headerName: 'Applied Qty', colId: 'itdQty', valueGetter: 'data.itemDetails.length', filter: 'agNumberColumnFilter' },
    ];
    this.updateJodMasterDetail(this.jodGridOptions);
    AgFns.initGrid(this.jodGridOptions, colDefs, null, true);
  }

  updateJodMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions: GridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      { headerName: 'Price/Unit', field: 'unitPriceAmt' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const jod = params.data as JoDetail;
        params.successCallback(jod.joDetailAddons);
      },
    };
  }

  onItdMatchGridReady(evt: any) {
    const colDefs = [
      { field: 'fitness', rowGroup: true, hide: true, order: 'asc' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', aggFunc: 'first', enableValue: true },
      { headerName: 'Description', field: 'product.productType.description', aggFunc: 'first', enableValue: true },
      { headerName: 'SKU', field: 'product.productType.style', aggFunc: 'first', enableValue: true },
      { headerName: 'Features', field: 'product.featureChoicesExtract', rowGroup: true, hide: false },
      { headerName: 'JO Detail Id', field: 'joDetailId',  },
      { headerName: 'Bin', field: 'itemBin.name',  },
      { headerName: 'Account', field: 'account.accountName',  },
      // { headerName: 'Account Owned', field: 'isAccountOwned', editable: false, cellRenderer: AgCheckboxCellComponent },
      { ...AgFns.createCellButtonProps('Mark Found', this.selectItemDetailCell),  },
      { headerName: 'Item Detail Id', field: 'id',  },
    ];

    this.itdMatchGridOptions.api.addAggFunc('first', (values) => _.first(values as any));
    this.itdMatchGridOptions.initialGroupOrderComparator = (params: InitialGroupOrderComparatorParams) => {
      const cA = _.first(params.nodeA.allLeafChildren);
      const cB = _.first(params.nodeB.allLeafChildren);
      const fitnessA = cA ? cA.data.fitness : 0;
      const fitnessB = cA ? cB.data.fitness : 0;

      if (fitnessA < fitnessB) {
        return -1;
      } else if (fitnessA > fitnessB) {
        return 1;
      } else {
       return 0;
      }
    };
    const sortModel = [
      { colId: 'isAccountOwned', sort: 'desc' as const },
    ];

    // expand 1st level automatically
    this.itdMatchGridOptions.groupDefaultExpanded = 1;

    AgFns.initGrid(this.itdMatchGridOptions, colDefs, sortModel, true);

    // this.itdMatchGridOptions.groupHideOpenParents = true;

    // Next line needs to appear AFTER initGrid above
    // this.itdMatchGridOptions.groupSuppressAutoColumn = true; // call was deprecated - replaced by line below
    this.itdMatchGridOptions.groupDisplayType='custom';

  }

  onItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'id',  cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style', },
      { headerName: 'Features', field: 'product.featureChoicesExtract',  },
    ];
    this.updateItdMasterDetail(this.itdGridOptions);
    AgFns.initGrid(this.itdGridOptions, colDefs);
    this.itdGridOptions.api.setDomLayout('autoHeight');

  }

  updateItdMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'AddOn', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetail;
        params.successCallback(itd.itemDetailAddons);
      },
    };
  }


  async getMatchingItemDetails(jod: JoDetail) {
    if (jod == null) {
      return;
    }
    const productId = jod.productId;
    const productTypeId = jod.product.productType.id;
    const firstFeatureChoice = jod.product.getFirstFeatureChoice();
    // evaluate fitness
    // query already eliminates damaged/shipped etc items.
    let r = await this.dbQueryService.getAltItemDetailsForStyle(productTypeId);
    r = r.filter(itd => itd.accountId == null || jod.joHeader.accountId == itd.accountId);
    
    r.forEach( itd => {
      let fitness = '3 - Alt. style match, size not matched';
      if (itd.productId === productId) {
        fitness = '0 - Exact style & size match';
      } else if (itd.product.productTypeId === productTypeId) {
        fitness = '1 - Style match, size not matched';
      } else if (itd.product.getFirstFeatureChoice()  === firstFeatureChoice) {
        fitness = '2 - Alt. style & size match';
      }
      itd['fitness'] = fitness;
    });
    return r;
  }

  // Hit when scanning or typing in itemDetailId
  onInputFilled(event: { target: any }, fn: (value: any) => void) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength) {
      // bind is needed to associate the fn returned with 'this' component.
      fn.bind(this)(input.value);
    } else {
      this.errorMessage = '';
      this.itemDetails = null;
    }
  }

  canCheckItemDetail(itemDetail: ItemDetail) {
    return this.user.isPullStaff && itemDetail?.id && itemDetail.joDetailId == null;
  }

  // Hit when clicking 'Mark as scanned'
  selectItemDetail(itemDetail: ItemDetail) {
    this.processItemDetailId(itemDetail.id);
  }

  async processItemDetailId(itemDetailId: string) {
    this.errorMessage = 'Searching...';
    const r = await this.dbQueryService.getItemDetailById(itemDetailId);
    this.errorMessage = '';
    this.itemDetails = r ? [r] : [];
    
    this.UtilFns.wait(100);
    this.itdGridOptions.columnApi && this.itdGridOptions.columnApi.autoSizeAllColumns();
    
    if (r == null) {
      this.errorMessage = 'No matching inventory found';
      return;
    }

    if (r.joDetailId != null) {
      this.errorMessage = 'This inventory item is reserved for Job Order Detail: ' + r.joDetailId;
      return;
    }

    const idt = this.itemDetails[0];
    const jod = this.joDetails[0];
    this.isStyleSizeMismatch = idt.productId !== jod.productId;

    if (this.isStyleSizeMismatch) {
      if (idt.product.productTypeId !== jod.product.productTypeId) {
      // see if it is already in the Alt table
        this.alternativeAlreadyExists = jod.product.productType.productTypeAlts.some(ss =>
          ss.productTypeId === jod.product.productTypeId  && ss.altProductTypeId === idt.product.productTypeId);
      } else {
        this.alternativeAlreadyExists = true;
      }
      this.isValidAlternative = !this.alternativeAlreadyExists;
    } else {
      this.confirmSelection();
    }
    // TODO: not sure why this doesn't work
    // this.confirmButton.nativeElement.focus();
  }

  async confirmSelection() {
    const itd = this.itemDetails[0];
    const jod = this.joDetails[0];
    itd.joDetailId = jod.id;
    itd.barcodePrintStatusId = BarcodePrintStatusCode.None;
    
    
    itd.lastScanTs = new Date(Date.now());
    if (this.isStyleSizeMismatch && this.isValidAlternative) {
      const productTypeAlt = this.uow.createEntity(ProductTypeAlt,
        { productTypeId: jod.product.productTypeId, altProductTypeId:  itd.product.productTypeId });
    }
    // Now create ItemDetailAddons to match the JoDetailAddons
    const itdAddons = this.addonUtilsService.createItemDetailAddonsFromJoDetail(jod, itd, this.accountAddons);
    if (itd.itemDetailAddons.length > 0) {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingAddon;
    } else {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessPendingShipPrep;
    }
    const addonBins = await this.addonUtilsService.findOrCreateAddonBins([itd]);

    await this.addonUtilsService.checkAndRemoveEmptyBins(addonBins.map(x => x.id));

    await this.dbSaveService.saveChanges();
    if (this.shouldPrintBarcodes) {
      await this.barcodeService.printItemDetailBarcodesAndNewAddonBinLabels([itd])
    }
    

    this.jodGridOptions.api.redrawRows();
    this.dialogService.toast({ message: 'Item reserved in inventory' });
    this.itemDetails = null;
    this.itemDetailId = '';
    
    // refreshes the joDetailId column so that you can see what was just selected.
    this.itdMatchGridOptions.api.refreshCells();

    

    // check if all units applied
    if (jod.itemDetails.length >= jod.orderQty) {
      this.goBack();
    }
  }

  

}
